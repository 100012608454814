.calendarGrid {
  background-color: #9ea6ff31;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  text-align: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
}
.calendarHeader {
  font-weight: bold;
  /* min-width: 40px; */
  min-height: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #8568ff;
  word-wrap: break-word;
}
.calendarMain {
  /* min-width: 40px; */
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link-months {
  font-size: 14px;
}
.firstDay_0 {
  grid-column-start: 1;
}
.firstDay_1 {
  grid-column-start: 2;
}
.firstDay_2 {
  grid-column-start: 3;
}
.firstDay_3 {
  grid-column-start: 4;
}
.firstDay_4 {
  grid-column-start: 5;
}
.firstDay_5 {
  grid-column-start: 6;
}
.firstDay_6 {
  grid-column-start: 7;
}
.bg_color_red_10 {
  background-color: rgb(92, 42, 255, 1);
}
.bg_color_red_9 {
  background-color: rgb(92, 42, 255, 0.9);
}
.bg_color_red_8 {
  background-color: rgb(92, 42, 255, 0.8);
}
.bg_color_red_7 {
  background-color: rgb(92, 42, 255, 0.7);
}
.bg_color_red_6 {
  background-color: rgb(92, 42, 255, 0.6);
}
.bg_color_red_5 {
  background-color: rgb(92, 42, 255, 0.5);
}
.bg_color_red_4 {
  background-color: rgb(92, 42, 255, 0.4);
}
.bg_color_red_3 {
  background-color: rgb(92, 42, 255, 0.3);
}
.bg_color_red_2 {
  background-color: rgb(92, 42, 255, 0.2);
}
.bg_color_red_1 {
  background-color: rgb(92, 42, 255, 0.1);
}
