.alert-dismissible {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 80px;
  padding: 8px 20px;
  max-width: 85%;
  margin: auto;
  margin-bottom: 15px;
  left: 0;
  right: 0;
  z-index: 1500;
}

.alert-dismissible .btn-close {
  position: relative;
  padding: 14px;
}
